.selectionContainerOverlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    opacity: 0;
    justify-content: flex-start;
    align-items: start;
    transition: transform 0.4s ease-in-out, opacity .4s ease-in-out;
    z-index: 1000;
    transform: translateX(-100%);
}

.selectionContainerOverlay.show {
    transform: translateX(0);
    opacity: 1;
}


.selectionContainerContent {
    width: 300px; /* Adjust width as needed */
    height: 100%;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    background-color: #FFFFFF;
}

.selectionContainerOverlay.show .selectionContainerContent {
    transform: translateX(0);
}
