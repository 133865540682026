.GraphContainer {
    display: flex;
    width: 100%;
    padding: 24px;
    background: #EBF5FF;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 20px;
    overflow-y: scroll;
}

.InnerContainer {
    display: flex;
    flex-direction: row;
}

.Info {
    color: #333333;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    padding-left: 10px;
    flex-wrap: wrap;
}

.BarChartContainer {
    width: 400px;
    height: 600px;
    background-color: white;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #E4E4E4;
    margin-right: 24px;
}

.BarChartContainerEnd {
    width: 400px;
    height: 600px;
    background-color: white;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #E4E4E4;
}

.BarChartContainerBottom {
    width: 400px;
    height: 600px;
    background-color: white;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #E4E4E4;
    margin-right: 24px;
    margin-bottom: 24px;
}

.BarChartContainerBottomEnd {
    width: 400px;
    height: 600px;
    background-color: white;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #E4E4E4;
    margin-bottom: 24px;
}

/* Mobile adjustments */
@media (max-width: 768px) {
    .InnerContainer {
        flex-direction: column; /* Stack charts vertically on small screens */
        align-items: center;
    }
    .GraphContainer{
        margin-top: 20px;
        padding: 0;
        width: 100vw;
        overflow-y: auto;
        padding-right: 10px;
        padding-left: 10px;
        padding-bottom: 20px;
        overflow-x: hidden;
    }
    .BarChartContainer,
    .BarChartContainerEnd,
    .BarChartContainerBottom,
    .BarChartContainerBottomEnd {
        width: 80vw; /* Adjust width to fit screen */
        height: 400px; /* Adjust height to fit content */
        margin-right: 0; /* Remove right margin for stacked elements */
        margin-bottom: 24px; /* Add bottom margin for spacing */
        padding:20px;

    }
}
