.Selection {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 20px;
}

.PlatformName {
    color: #757575;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 12px;
}

.VersionContainer {
    margin-top: 10px;
}

.InputLabel {
    font-size: 20px;
    color: black;
}

.Button {
    width: 100%;
    margin-top: 20px;
    align-self: flex-start;
}

.PlatformNameTypeContainer {
    display: flex;
    flex-direction: row;
}

/* Mobile adjustments */
@media (max-width: 768px) {
    .Selection {
        flex-direction: column;
        gap: 8px;
    }

    /*.PlatformNameTypeContainer {*/
    /*    flex-direction: column; !* Stack checkboxes vertically *!*/
    /*}*/

    .Button {
        width: 100%;
    }
}
