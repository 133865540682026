.SelectionContainer {
    display: flex;
    padding: 20px;
    border: 1px solid #D9D9D9;
    background: #FFFFFF;
    flex-direction: column;
    gap: 12px;
    min-width: 320px;
    max-width: 320px;
    max-height: 100vh;
    height: auto;
    overflow-y: auto;
    box-sizing: border-box;
}

.SelectionContainerHeader {
    color: #333333;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 10px;
}

.DeviceContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 80%;
    padding-bottom: 10px; /* Add some padding at the bottom */
}

.Title {
    color: #333333;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    margin-right: 10px; /* Add some margin between title and chips */
}

.Note {
    display: flex;
    color: gray;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 16px;
}

.NoteContainer {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    justify-content: center;
}
.Link {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
/* Mobile adjustments */
@media (max-width: 768px) {
    .SelectionContainer {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.9);
        z-index: 999;
        overflow-y: auto;
    }
    /*.SelectionContainer {*/
    /*    padding: 10px;*/
    /*    min-width: 100%;*/
    /*    max-width: 100%;*/
    /*    max-height: none;*/
    /*    height: auto; !* Ensure container height adjusts with content *!*/
    /*}*/

    .SelectionContainerHeader {
        font-size: 12px; /* Adjust font size for smaller screens */
    }
}
