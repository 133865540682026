.HeaderContainer {
    background: #FFFFFF;
    display: flex;
    width: 100%;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 24px;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
}

.HeaderTitle {
    color: #757575;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}
